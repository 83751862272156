import {
  BacklinkSettingsDataV2,
  PreReleaseSettings,
} from 'shared/entities/backlinkSettings/v2/types';

const getCurrentPreReleaseStoreList = (
  formState: BacklinkSettingsDataV2,
): PreReleaseSettings['stores']['list'] => {
  if (formState.preferences.useLocalizedStoresList) {
    const storesList = Object.keys(formState.preRelease.locale).reduce(
      (acc, locale) => {
        return acc.concat(formState.preRelease.locale[locale].stores.list);
      },
      [],
    );
    return [...new Set(storesList)];
  }
  return formState.preRelease.stores.list;
};

export const getCurrentPreReleaseStoreOptions = (
  formState: BacklinkSettingsDataV2,
): PreReleaseSettings['stores']['options'] => {
  const storeList = getCurrentPreReleaseStoreList(formState);
  return storeList.reduce<PreReleaseSettings['stores']['options']>(
    (acc, storeId) => {
      return {
        ...acc,
        [storeId]: formState.preRelease.stores.options[storeId],
      };
    },
    {},
  );
};
export const getStoresOptionsWithFrontEndOnlyDSPActions = (
  storesOptions: BacklinkSettingsDataV2['preRelease']['stores']['options'],
) => {
  return Object.keys(storesOptions).reduce((acc, storeName) => {
    const store = { ...storesOptions[storeName] };
    if (store.dspActions) {
      store.dspActions = store.dspActions.filter(
        (action) => action.dspActionId === null,
      );
    }
    acc[storeName] = store;
    return acc;
  }, {} as BacklinkSettingsDataV2['preRelease']['stores']['options']);
};
