import { useRef, useState } from 'react';

import {
  getLocalesCodes,
  getMainLocaleCode,
} from 'shared/entities/backlinkSettings/backlinkSettings.helpers';
import { AllowedLocale } from 'shared/entities/backlinkSettings/backlinkSettings.locale.types';
import { BacklinkSettingsData } from 'shared/entities/backlinkSettings/backlinkSettings.types';

type Params = {
  editorState: BacklinkSettingsData;
  onCurrentLocaleChange: (locale: string) => void;
};

const useEditorLocale = ({ editorState, onCurrentLocaleChange }: Params) => {
  /**
   * note: as we want to retrigger some actions (namely: sync CTAs) as the locales list changes
   * we keep a ref to the available locales as an array of iso code strings and compare the stringified array
   * this is to avoid false positives caused by the js array being referentially different but holding the actual same list
   */
  const localesRef = useRef<AllowedLocale[]>();
  const editorLocalesCodes = getLocalesCodes(editorState);
  if (
    JSON.stringify(localesRef.current) !== JSON.stringify(editorLocalesCodes)
  ) {
    localesRef.current = editorLocalesCodes;
  }

  const locales = localesRef.current || [];
  const mainLocaleCode = getMainLocaleCode(editorState) as AllowedLocale;

  /** current locale */
  const [currentLocale, setCurrentLocale] = useState(mainLocaleCode);

  const handleCurrentLocaleChange = (locale: AllowedLocale) => {
    setCurrentLocale(locale);
    onCurrentLocaleChange(locale);
  };

  return { locales, currentLocale, handleCurrentLocaleChange };
};

export default useEditorLocale;
