import qs from 'qs';

import { api } from '../api';
import {
  PaginatedTrackPitchesRequest,
  PaginatedTrackPitchesResponse,
  TrackPitchExportRequest,
  UpdateTrackPitchRequestData,
  TrackPitchExportResponse,
  TrackPitchExportStatusRequest,
  TrackPitchExportStatusResponse,
  TrackPitchFiltersResponse,
  TrackpitchTogglePitchedOnStoreResponse,
  TrackpitchMarkAsPitchedOnStoreRequestBody,
  FetchTrackPitchCostCentersAdminParams,
  TrackPitchBatchUpdateRequest,
  BatchUpdateTrackpitchResponse,
  TrackPitchDetailsResponse,
  TrackpitchFollowUpResponsePayload, TrackpitchSyncAnalyticsResponsePayload,
} from './trackPitch.api.types';
import {
  TrackpitchCostCenter,
  TrackPitchDetails,
  TrackpitchStore,
  TrackPitchStreamingInfos,
} from './trackPitch.types';
import {
  TrackPitchPreFilterValue
} from "../../../apps/MarketingSuite/sections/Campaigns/sections/TrackPitches/components/TrackPitchPreFilter/TrackPitchPreFilter";

export const TRACK_PITCH_BASE_ROUTE = '/trackpitches';
export const EXPORT_BASE_ROUTE = '/exports';

export async function fetchTrackPitches(params: PaginatedTrackPitchesRequest) {
  const response = await api.post<PaginatedTrackPitchesResponse>(
    `${TRACK_PITCH_BASE_ROUTE}`,
    params,
  );
  return response.data;
}

export const fetchTrackPitch = async (
  trackPitchId: string,
): Promise<TrackPitchDetails> => {
  const results = await api.get(`${TRACK_PITCH_BASE_ROUTE}/${trackPitchId}`);
  return results.data;
};

export const updateTrackPitch = async ({
  trackPitchId,
  data,
}: {
  trackPitchId: string;
  data: UpdateTrackPitchRequestData;
}): Promise<TrackPitchDetailsResponse> => {
  const results = await api.patch(
    `${TRACK_PITCH_BASE_ROUTE}/${trackPitchId}`,
    data,
  );
  return results.data;
};

export const batchUpdateTrackPitches = async (
  params: TrackPitchBatchUpdateRequest,
): Promise<BatchUpdateTrackpitchResponse> => {
  const results = await api.patch(`${TRACK_PITCH_BASE_ROUTE}/batch`, params);
  return results.data;
};

export const exportTrackPitches = async (
  params?: TrackPitchExportRequest,
): Promise<TrackPitchExportResponse> => {
  const results = await api.post(
    `${EXPORT_BASE_ROUTE}${TRACK_PITCH_BASE_ROUTE}`,
    params,
  );
  return results.data;
};

export const getExportStatus = async (
  params: TrackPitchExportStatusRequest,
): Promise<TrackPitchExportStatusResponse> => {
  const results = await api.get(
    `${EXPORT_BASE_ROUTE}${TRACK_PITCH_BASE_ROUTE}/status`,
    {
      params,
    },
  );
  return results.data;
};

// request contexts by Date
export const fetchTrackPitchesContexts = async (params: {
  pitchDate: string;
  workspaceId?: string;
  preFilter?: TrackPitchPreFilterValue;
}): Promise<TrackPitchFiltersResponse> => {
  const results = await api.get(`${TRACK_PITCH_BASE_ROUTE}/contexts`, {
    params,
  });
  return results.data;
};

/**
 * Toggle whether a Release has been pitched to a given platform
 * */
export const toggleTrackPitchPitchedOnStore = async ({
  trackPitchId,
  storeId,
  pitched,
}: {
  trackPitchId: string;
  storeId: TrackpitchStore;
  pitched: boolean;
}) => {
  const baseRoutePath = `${TRACK_PITCH_BASE_ROUTE}/${trackPitchId}/store`;
  const markAsPitchedOnStorePayload: TrackpitchMarkAsPitchedOnStoreRequestBody =
    {
      platform: storeId,
    };
  const results = pitched
    ? await api.post<TrackpitchTogglePitchedOnStoreResponse>(
        baseRoutePath,
        markAsPitchedOnStorePayload,
      )
    : await api.delete<TrackpitchTogglePitchedOnStoreResponse>(
        `${baseRoutePath}/${storeId}`,
      );
  return results.data;
};

/**
 * Get the streaming token for a given track pitch
 */
export const getTrackPitchStreamingToken = async ({
  trackPitchId,
}: {
  trackPitchId: string;
}) => {
  try {
    const results = await api.get<TrackPitchStreamingInfos>(
      `${TRACK_PITCH_BASE_ROUTE}/streaming-token/${trackPitchId}`,
    );
    return results.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Fetch all cost centers, to be used in admin view
 */
export const fetchTrackPitchCostCentersAdmin = async ({
  page,
  limit,
  sortBy,
  sortDirection,
  searchQuery,
}: FetchTrackPitchCostCentersAdminParams): Promise<{
  costCenters: TrackpitchCostCenter[];
  totalCount: number;
}> => {
  const results = await api.get(
    `${TRACK_PITCH_BASE_ROUTE}/admin/cost-centers`,
    {
      params: {
        page,
        limit,
        sortBy,
        sortDirection,
        searchQuery,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    },
  );
  return results.data;
};

/**
 * Update cost center by id, to be used in admin view
 */
export const updateTrackPitchCostCenterAdmin = async (
  costCenterId: string,
  costCenter: Partial<TrackpitchCostCenter>,
) => {
  const results = await api.patch(
    `${TRACK_PITCH_BASE_ROUTE}/admin/cost-centers/${costCenterId}`,
    costCenter,
  );
  return results.data;
};

export const fetchTrackpitchFollowUp = async ({
  trackpitchId,
}: {
  trackpitchId: string;
}) => {
  const results = await api.get<TrackpitchFollowUpResponsePayload>(
    `${TRACK_PITCH_BASE_ROUTE}/admin/follow-up/${trackpitchId}`,
  );
  return results.data;
};

export const fetchTrackpitchSyncAnalytics = async (page = 1, from = new Date()) => {
  const results = await api.get<TrackpitchSyncAnalyticsResponsePayload>(
    `${TRACK_PITCH_BASE_ROUTE}/admin/sync-analytics`,
  );
  return results.data;
};
