import produce from 'immer';

import { BacklinkSettingsDataV2 } from 'shared/entities/backlinkSettings/v2/types';
import {
  DSPActionResponsePayload,
  DSPActionCreateOrUpdatePayload,
} from 'shared/entities/dspAction/dspAction.api.types';
import {
  DSP,
  DSPActionScheduleType,
  DSPActionType,
} from 'shared/entities/dspAction/dspAction.constants';
import {
  DSPAction,
  isDSPActionType,
} from 'shared/entities/dspAction/dspAction.types';
import { OptionalProperty } from 'shared/types/utils';

import {
  FrontendDSPActionStatus,
  FrontendDSPActionType,
} from './dspAction.frontend.constants';
import {
  FrontendDSPAction,
  isFrontendDSPActionType,
} from './dspAction.frontend.types';

/**
 * Get the status of a given DSP action.
 * for the MVP of backlink v2 we compute the status frontend-side
 */
function getDSPActionStatus(
  dspAction: FrontendDSPAction,
  releaseDate: string,
): FrontendDSPActionStatus {
  const now = new Date();

  // instant
  if (dspAction.scheduleType === DSPActionScheduleType.SCHEDULED_NOW) {
    return FrontendDSPActionStatus.SCHEDULED;
  }

  // draft
  if (
    dspAction.scheduleType === DSPActionScheduleType.SCHEDULED_WITH_DATE &&
    !dspAction.scheduleDate
  ) {
    return FrontendDSPActionStatus.DRAFT;
  }

  // custom schedule date in the future
  if (
    dspAction.scheduleType === DSPActionScheduleType.SCHEDULED_WITH_DATE &&
    dspAction.scheduleDate &&
    new Date(dspAction.scheduleDate) > now
  ) {
    return FrontendDSPActionStatus.SCHEDULED;
  }

  // backlink release date in the future
  if (
    dspAction.scheduleType ===
      DSPActionScheduleType.SCHEDULED_ON_BACKLINK_RELEASE_DATE &&
    new Date(releaseDate) > new Date()
  ) {
    return FrontendDSPActionStatus.SCHEDULED;
  }

  // date in the past
  return FrontendDSPActionStatus.DELIVERED;
}

export const groupByPlatform = (dspActions: FrontendDSPAction[]) => {
  return dspActions.reduce((acc, dspAction) => {
    if (!dspAction) {
      return acc;
    }
    return {
      ...acc,
      [dspAction.storeName]: [...(acc[dspAction.storeName] || []), dspAction],
    };
  }, {} as Record<string, FrontendDSPAction[]>);
};

export const mergeFrontendDspActionsIntoBacklinkSettings = ({
  backlinkSettings,
  dspActions,
}: {
  backlinkSettings: BacklinkSettingsDataV2;
  dspActions: FrontendDSPAction[];
}) => {
  return produce(backlinkSettings, (draft) => {
    // dspActions status
    const dspActionsWithStatus = dspActions.map((dspAction) => ({
      ...dspAction,
      status: getDSPActionStatus(dspAction, draft?.release.digitalReleaseDate),
    }));

    const dspActionsByPlatform =
      dspActions && groupByPlatform(dspActionsWithStatus);

    if (!dspActionsByPlatform) {
      return;
    }

    Object.keys(dspActionsByPlatform).forEach((storeName) => {
      const dspStoreOptions = draft?.preRelease.stores.options[storeName];

      if (!dspStoreOptions) {
        return;
      }

      if (dspStoreOptions?.dspActions) {
        const dspActions = dspStoreOptions.dspActions;
        dspActionsByPlatform[storeName].forEach((DbDspAction) => {
          // we want to only keep the backlinkSettings Actions (frontend only) that are not in the fetched DSP Actions from the backend
          // we only want 1 frontend-only action per ActionType in the backlinkSettings
          // note: editing or adding a DSP Action will remove all frontend-only actions of the same type
          const indexForfrontendOnlyActionOfType = dspActions.findIndex(
            (dspAction) =>
              dspAction.dspActionId === null &&
              (DbDspAction.type === dspAction.type ||
                // special cases where some actions coming from the backend are considered equivalent to some frontend specific actions
                (DbDspAction.type === FrontendDSPActionType.FOLLOW_ARTIST &&
                  dspAction.type ===
                    FrontendDSPActionType.FOLLOW_MAIN_ARTIST) ||
                (DbDspAction.type === FrontendDSPActionType.ADD_PLAYLIST &&
                  dspAction.type === FrontendDSPActionType.ADD_MAIN_PLAYLIST)),
          );
          if (
            indexForfrontendOnlyActionOfType !== undefined &&
            indexForfrontendOnlyActionOfType !== -1
          ) {
            dspActions[indexForfrontendOnlyActionOfType] = DbDspAction;
          } else {
            // Check if the DSP action already exists in the store options and update it if found
            const indexForExistingAction = dspActions.findIndex(
              (dspAction) => dspAction.dspActionId === DbDspAction.dspActionId,
            );
            if (indexForExistingAction !== -1) {
              dspActions[indexForExistingAction] = DbDspAction;
            } else {
              dspActions.push(DbDspAction);
            }
          }
        });
      }
    });
  });
};

export const isFrontendDSPAction = (obj: unknown): obj is FrontendDSPAction => {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    'dspActionId' in obj &&
    'storeName' in obj &&
    'type' in obj &&
    'targetName' in obj &&
    'targetURL' in obj &&
    'scheduleType' in obj
  );
};

type TransformDSPActionToFrontendDSPActionOptions = {
  isFollowMainArtist?: boolean;
  isAddMainPlaylist?: boolean;
};

export const transformDSPActionTypeToFrontendDSPActionType = (
  dspActionType: DSPActionType,
  options?: TransformDSPActionToFrontendDSPActionOptions,
): FrontendDSPActionType => {
  switch (dspActionType) {
    case DSPActionType.FOLLOW_ARTIST:
      return options?.isFollowMainArtist
        ? FrontendDSPActionType.FOLLOW_MAIN_ARTIST
        : FrontendDSPActionType.FOLLOW_ARTIST;
    case DSPActionType.ADD_PLAYLIST:
      return options?.isAddMainPlaylist
        ? FrontendDSPActionType.ADD_MAIN_PLAYLIST
        : FrontendDSPActionType.ADD_PLAYLIST;
    default: {
      if (isFrontendDSPActionType(dspActionType)) {
        return FrontendDSPActionType[dspActionType];
      }
      throw new Error(
        `Can not transform DSPActionType: ${dspActionType} into FrontendDSPActionType`,
      );
    }
  }
};

export const transformDSPActionToFrontendDSPAction = (
  dspAction: DSPActionResponsePayload,
  options?: TransformDSPActionToFrontendDSPActionOptions,
): FrontendDSPAction | null => {
  const { isFollowMainArtist, isAddMainPlaylist } = options || {};

  if (!dspAction.config.platforms) {
    return null;
  }

  return {
    dspActionId: dspAction.id,
    storeName: Object.keys(dspAction.config.platforms)[0] as DSP,
    targetName: dspAction.targetName,
    targetURL: Object.values(dspAction.config.platforms)[0].targetUrl || '',
    targetId: Object.values(dspAction.config.platforms)[0].targetId || '',
    type: transformDSPActionTypeToFrontendDSPActionType(dspAction.type, {
      isFollowMainArtist,
      isAddMainPlaylist,
    }),
    scheduleType: dspAction.scheduleType,
    scheduleDate: dspAction.schedulableOn,
    selectedTimezone: dspAction.config.selectedTimezone,
    // status is computed frontend-side - it will be updated when the backedn actions are fetched and merged with the frontend actions
    // we start with a default status of DRAFT
    status: FrontendDSPActionStatus.DRAFT,
  };
};

export const transformAllDSPActionToFrontendDSPAction = (
  dspActions: DSPActionResponsePayload[],
  {
    helpers: { checkIsFollowMainArtistAction },
  }: {
    helpers: {
      checkIsFollowMainArtistAction: (dspAction: DSPAction) => boolean;
    };
  },
) => {
  return dspActions
    .map((dspAction) => {
      /**
       * we need to convert some dsp actions types to frontend-only action types that we need
       * for now we add some frontend logic but might have to rely on some info from the backend that will be attached to the dspAction we receive
       */
      // we need to know:
      // - if the dspAction is a FOLLOW_MAIN_ARTIST
      const isFollowMainArtist = checkIsFollowMainArtistAction(dspAction);

      // - if the dspAction is an add main playlist
      // here we rely on the actions to be returned sorted by creation date
      // the first ADD_PLAYLIST action is considered the ADD_MAIN_PLAYLIST action
      const addPlaylistActions = dspActions.filter(
        (value) =>
          value.type === DSPActionType.ADD_PLAYLIST &&
          Object.keys(value.config.platforms)[0] ===
            Object.keys(dspAction.config.platforms)[0],
      );
      const isAddMainPlaylist =
        addPlaylistActions.findIndex(
          (playlistAction) => playlistAction.id === dspAction.id,
        ) === 0;

      return transformDSPActionToFrontendDSPAction(dspAction, {
        isFollowMainArtist,
        isAddMainPlaylist,
      });
    })
    .filter(Boolean) as FrontendDSPAction[];
};

export const transformFrontendDSPActionTypeToDSPActionType = (
  dspActionType: FrontendDSPActionType,
): DSPActionType => {
  if (isDSPActionType(dspActionType)) {
    return dspActionType;
  }

  switch (dspActionType) {
    case FrontendDSPActionType.FOLLOW_MAIN_ARTIST:
      return DSPActionType.FOLLOW_ARTIST;
    case FrontendDSPActionType.ADD_MAIN_PLAYLIST:
      return DSPActionType.ADD_PLAYLIST;
    default:
      throw new Error(`Invalid FrontendDSPActionType: ${dspActionType}`);
  }
};

export const transformFrontendDSPActionToDSPActionPayload = (
  dspAction: OptionalProperty<FrontendDSPAction, 'status'>,
): DSPActionCreateOrUpdatePayload => {
  return {
    scheduleType: dspAction.scheduleType,
    targetName: dspAction.targetName,
    type: transformFrontendDSPActionTypeToDSPActionType(dspAction.type),
    schedulableOn: dspAction.scheduleDate,
    config: {
      platforms: {
        [dspAction.storeName]: {
          targetUrl: dspAction.targetURL,
          targetId: dspAction.targetId,
        },
      },
      selectedTimezone: dspAction.selectedTimezone,
    },
  };
};
