import deepmerge from 'deepmerge';
import produce, { Draft } from 'immer';
import get from 'lodash/get';

import { BacklinkReleaseMode } from 'shared/entities/backlink/backlink.types';
import {
  getCTAsPathsInfos,
  syncCTAsWithReleaseStores,
} from 'shared/entities/backlinkSettings/backlinkSettings.helpers';
import {
  BacklinkSettingsData,
  BacklinkSettingsStepsTemplate,
} from 'shared/entities/backlinkSettings/backlinkSettings.types';
import { BacklinkReleaseStore } from 'shared/entities/release/release.types';
import { StoresConfigs } from 'shared/entities/storeConfig/storeConfig.types';

export type BacklinkSettingsDataUpdateSettingOptions = Partial<{
  replace: boolean;
}>;
/**
 * make next state - using immer
 * relying on the fact that each field of the Editor
 * is passed a path to the property it is associated with in the editorState object
 * for instance:
 * path to key: 'releaseStep.release.stores.spotify.displayName'
 * when dealing with object values we can either deepmerge with target or replace
 * it is possible to pass an options object param that will allow to change behaviour by having a 'replace' key set to true
 *
 * note: arrays are always replaced
 *
 * note 2: we have tried to have the transform data mechanism (that replaces some values by others in the backlink data tree)
 * setup inside the update function - but this breaks when there are update calls in a useEffect (infinite loop)
 * this was tried to be able to replace values as we were editing the backlink (rather than just on loading it in the editor)
 * TODO: find a way (?)
 */
export const makeEditorNextState = produce(
  (
    draft: Draft<BacklinkSettingsData>,
    path: string,
    data: any,
    options: BacklinkSettingsDataUpdateSettingOptions = {},
  ) => {
    options.replace = options.replace || false;

    // extract path to the key of the property
    const keys = path.split('.');
    // propertyKey is the last element in a string path like 'release.stores.spotify.displayName'
    const propertyKey = keys.splice(-1).join('');

    const parentObject = !!(!keys.length && draft[propertyKey])
      ? draft
      : get(draft, keys);

    // path should exist (no property creation for now)
    if (!parentObject) {
      console.error(
        'Backlink Editor - makeEditorNextState - You are trying to update a property on a non-existent object',
        keys,
      );
      return;
    }

    // mutate the draft at the desired depth - i.e. update the property
    parentObject[propertyKey] =
      typeof parentObject[propertyKey] === 'object' &&
      !Array.isArray(parentObject[propertyKey]) && // always replace arrays
      !options.replace
        ? deepmerge(parentObject[propertyKey], data)
        : data;
  },
);

export const deleteLocaleSettings = produce(
  (draft: Draft<BacklinkSettingsData>, locale: string) => {
    delete draft.prereleaseLandingStep.locale[locale];
    delete draft.prereleasePresaveStep.locale[locale];
    delete draft.prereleaseFormStep.locale[locale];
    delete draft.prereleaseAutonotifyStep.locale[locale];
    delete draft.postreleaseLandingStep.locale[locale];
  },
);

export const addLocaleSettings = produce(
  (
    draft: Draft<BacklinkSettingsData>,
    locale: string,
    settings: BacklinkSettingsStepsTemplate,
    // TODO add optional transform
  ) => {
    draft.prereleaseLandingStep.locale[locale] = settings.prereleaseLandingStep;
    draft.prereleasePresaveStep.locale[locale] = settings.prereleasePresaveStep;
    draft.prereleaseFormStep.locale[locale] = settings.prereleaseFormStep;
    draft.prereleaseAutonotifyStep.locale[locale] =
      settings.prereleaseAutonotifyStep;
    draft.postreleaseLandingStep.locale[locale] =
      settings.postreleaseLandingStep;
  },
);

export function syncAllCTAsWithReleaseStores({
  state,
  releaseStores,
  storesConfigs,
}: {
  state: BacklinkSettingsData;
  releaseStores: Record<string, BacklinkReleaseStore>;
  storesConfigs: StoresConfigs;
}) {
  let nextState = state;
  getCTAsPathsInfos(state).forEach((pathInfos) => {
    const CTAs = syncCTAsWithReleaseStores({
      releaseStores,
      storesConfigs,
      CTAs: get(state, pathInfos.path),
      step: pathInfos.step,
      locale: pathInfos.locale,
    });
    nextState = makeEditorNextState(nextState, pathInfos.path, CTAs, {
      replace: true,
    });
  });

  return nextState;
}

export const getReleaseMode = (
  backlinkEditorState: BacklinkSettingsData,
): BacklinkReleaseMode =>
  backlinkEditorState.releaseStep.release.digitalReleaseDate &&
  new Date(
    backlinkEditorState.releaseStep.release.digitalReleaseDate,
  ).getTime() > Date.now()
    ? 'pre-release'
    : 'post-release';
