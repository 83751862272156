import { AxiosRequestConfig } from 'axios';
import { api } from '../api';
import type { AdCampaignDTO, AdCampaignStatsDTO } from './ads.api.dtos.types';
import {
  FetchAvailablePlanStatusesResponse,
  CreateAdCampaignPayload,
  CreateAdCampaignResponse,
  DuplicateAdCampaignResponse,
  FetchAdsListResponse,
  GetReleaseAvailableChannelsResponse,
  ReferentialAgeRangeResponse,
  ReferentialFbCTAParams,
  ReferentialFbCTAResponse,
  ReferentialInterestsParams,
  ReferentialInterestsResponse,
  ReferentialLocationsParams,
  ReferentialLocationsResponse,
  ReferentialStandardInterestsParams,
  ReferentialStandardInterestsResponse,
  UpdateAdCampaignPayload,
  UpdateAdCampaignResponse,
  FetchAvailableReleaseTypeResponse,
  FetchAvailableSanitycheckResponse as FetchAvailableSanitycheckStatusResponse,
  FetchAdsUsersResponse,
  RechargeIdResponse,
  ServiceCodesResponse,
  FetchAdsObjectiveResponse,
  CreateCampaignApiResponse,
  CampaignUpdateRequest,
  UpdateCampaignApiResponse,
  AdsPlanApiResponse,
  AdsApiResponse,
  UpdatePlanPayload,
  YoutubeLocationResponse,
  YoutubeFixedDataPayload,
  BusinessUnitsResponse,
  YoutubeInterestResponse,
  UpdateAdsPlacementPayload,
} from './ads.api.types';
import {
  AvailableAdsUsersProfileTypes as AvailableAdsUserProfileTypes,
  AvailablePlanStatus,
  OrderByParamsEnum,
} from './ads.constants';
import {
  AdCampaignModel,
  AdCampaignStatsModel,
  AgeRangeModel,
} from './ads.model';
import type {
  AdCampaign,
  AdCampaignStats,
  AvailableCredits,
  DraftAdCampaign,
  Interest,
  Location,
  ReferentialAgeRanges,
  AdsSettingsCustomer,
  GetAllAdsSettingsCustomerPayload,
  FbCTA,
  Ads,
  AdsUserEmployee,
  GetAdsSummaryFilters,
  AdsObjective,
  AdsPlanCampaign,
  YoutubeTargetingType,
  YoutubePlacement,
  UpdateAudiencePayload,
} from './ads.types';

export const ADS_API_BASE_URL = '/ads';

// GET /ads/credits - getAvailableCredits
export async function getAvailableCredits(): Promise<AvailableCredits> {
  const response = await api.get(`${ADS_API_BASE_URL}/credits`);
  return response.data;
}

// GET /ads/release/:releaseId/channels - getReleaseAvailableChannels
export async function getReleaseAvailableChannels(
  releaseId: string,
): Promise<GetReleaseAvailableChannelsResponse> {
  const response = await api.get(
    `${ADS_API_BASE_URL}/release/${releaseId}/channels`,
  );
  return response.data;
}

// GET /ads/release/:releaseId/subcampaign - getReleaseAdCampaigns
export async function getReleaseAdCampaigns(
  releaseId: string,
): Promise<Array<AdCampaign>> {
  const response = await api.get<AdCampaignDTO[]>(
    `${ADS_API_BASE_URL}/release/${releaseId}/subCampaign`,
  );
  return (response.data || []).map(AdCampaignModel.transformFromDTO);
}

// GET /ads/release/:releaseId/subcampaign/:subcampaignKey - getAdCampaignByKey
export async function getAdCampaignByKey(
  releaseId: string,
  adCampaignKey: string,
): Promise<AdCampaign> {
  const response = await api.get<AdCampaignDTO>(
    `${ADS_API_BASE_URL}/release/${releaseId}/subCampaign/${adCampaignKey}`,
  );
  return AdCampaignModel.transformFromDTO(response.data);
}

// GET /ads/release/:releaseId/subCampaign/:subCampaignKey/statistics - getAdCampaignStatistics
export async function getAdCampaignStats(
  releaseId: string,
  adCampaignKey: string,
): Promise<AdCampaignStats> {
  const response = await api.get<AdCampaignStatsDTO>(
    `${ADS_API_BASE_URL}/release/${releaseId}/subCampaign/${adCampaignKey}/statistics`,
  );
  return AdCampaignStatsModel.transform(response.data);
}

// POST /ads/release/:releaseId/subCampaign - createAdCampaign
export async function createAdCampaign({
  releaseId,
  payload,
}: {
  releaseId: string;
  payload: CreateAdCampaignPayload;
}): Promise<DraftAdCampaign> {
  const response = await api.post<CreateAdCampaignResponse>(
    `${ADS_API_BASE_URL}/release/${releaseId}/subCampaign`,
    payload,
  );
  return AdCampaignModel.transformFromDTO(response.data.subCampaign);
}

// PATCH /ads/release/:releaseId/subcampaign/:subcampaignId - updateAdCampaign
export async function updateAdCampaign({
  releaseId,
  adCampaignKey,
  payload,
}: {
  releaseId: string;
  adCampaignKey: string;
  payload: UpdateAdCampaignPayload;
}): Promise<AdCampaign> {
  const response = await api.patch<UpdateAdCampaignResponse>(
    `${ADS_API_BASE_URL}/release/${releaseId}/subCampaign/${adCampaignKey}`,
    payload,
  );
  return AdCampaignModel.transformFromDTO(response.data.subCampaign);
}

// POST /ads/release/:releaseId/subcampaign/:subcampaignId/boost - boostAdCampaign
export async function launchAdCampaign({
  releaseId,
  adCampaignKey,
}: {
  releaseId: string;
  adCampaignKey: string;
}): Promise<{ success: boolean }> {
  const response = await api.post(
    `${ADS_API_BASE_URL}/release/${releaseId}/subCampaign/${adCampaignKey}/boost`,
  );
  return response.data;
}

// POST /ads/release/:releaseId/subcampaign/:subcampaignId/stop - stopAdCampaign
export async function stopAdCampaign({
  releaseId,
  adCampaignKey,
}: {
  releaseId: string;
  adCampaignKey: string;
}): Promise<{ success: boolean }> {
  const response = await api.post(
    `${ADS_API_BASE_URL}/release/${releaseId}/subCampaign/${adCampaignKey}/stop`,
  );
  return response.data;
}

// DELETE /ads/release/:releaseId/subcampaign/:subcampaignId - deleteAdCampaign
export async function deleteAdCampaign({
  releaseId,
  adCampaignKey,
}: {
  releaseId: string;
  adCampaignKey: string;
}): Promise<{ success: boolean }> {
  const response = await api.delete(
    `${ADS_API_BASE_URL}/release/${releaseId}/subCampaign/${adCampaignKey}`,
  );
  return response.data;
}

// POST /ads/release/:releaseId/subcampaign/:subcampaignId/duplicate - duplicateAdCampaign
export async function duplicateAdCampaign({
  releaseId,
  adCampaignKey,
}: {
  releaseId: string;
  adCampaignKey: string;
}): Promise<AdCampaign> {
  const response = await api.post<DuplicateAdCampaignResponse>(
    `${ADS_API_BASE_URL}/release/${releaseId}/subCampaign/${adCampaignKey}/duplicate`,
  );
  return AdCampaignModel.transformFromDTO(response.data.subCampaign);
}

/**
 * REFERENTIALS
 */
export async function getReferentialInterests(
  params: ReferentialInterestsParams,
): Promise<Interest[]> {
  const result = await api.get<ReferentialInterestsResponse>(
    `${ADS_API_BASE_URL}/referential/interests`,
    {
      params,
    },
  );
  return result.data.data;
}

export async function getReferentialStandardInterests(
  params: ReferentialStandardInterestsParams,
): Promise<Interest[]> {
  const result = await api.get<ReferentialStandardInterestsResponse>(
    `${ADS_API_BASE_URL}/referential/standardInterests`,
    { params },
  );
  return result.data.data;
}

export async function getReferentialLocations(
  params: ReferentialLocationsParams,
): Promise<Location[]> {
  const result = await api.get<ReferentialLocationsResponse>(
    `${ADS_API_BASE_URL}/referential/locations`,
    { params },
  );
  return result.data.data.locations;
}

export async function getAgeRanges(): Promise<ReferentialAgeRanges> {
  const result = await api.get<ReferentialAgeRangeResponse>(
    `${ADS_API_BASE_URL}/referential/ageRange`,
  );
  return AgeRangeModel.transform(result.data);
}

export async function getFbCTAs(
  params: ReferentialFbCTAParams,
): Promise<FbCTA[]> {
  const result = await api.get<ReferentialFbCTAResponse>(
    `${ADS_API_BASE_URL}/referential/fbCta`,
    { params },
  );
  return result.data.data;
}

/**
 * ADMIN
 */

export async function createAdsSettingsCustomer(
  data: AdsSettingsCustomer,
): Promise<AdsSettingsCustomer> {
  const results = await api.post('/ads/admin/adsSettingsCustomer', data);
  return results.data;
}

export async function updateAdsSettingsCustomer(
  id,
  data: AdsSettingsCustomer,
): Promise<AdsSettingsCustomer> {
  const results = await api.patch(`/ads/admin/adsSettingsCustomer/${id}`, data);
  return results.data;
}

export async function deleteAdsSettingsCustomer(id): Promise<void> {
  await api.delete(`/ads/admin/adsSettingsCustomer/${id}`);
}

export async function getAllAdsSettingsCustomer(
  requestPayload: GetAllAdsSettingsCustomerPayload,
): Promise<{ adsSettingsCustomersExtended: any; totalCount: number }> {
  const results = await api.get('/ads/admin/adsSettingsCustomer', {
    params: requestPayload,
  });
  return results.data;
}

export async function getAllAdsBusinessUnits(): Promise<string[]> {
  const results = await api.get('/ads/admin/businessUnits');
  return results.data;
}

export async function fetchAdsPlans(
  status: AvailablePlanStatus,
  limit: number,
  offset: number,
  search: string,
  plansFilters: GetAdsSummaryFilters,
  orderByParam?: OrderByParamsEnum,
) {
  const params = { status, limit, offset };

  if (search) params['search'] = search;

  if (orderByParam) params['orderBy'] = orderByParam;

  const fullParams = { ...params, ...plansFilters };

  const response = await api.get<FetchAdsListResponse<Ads>>(
    `${ADS_API_BASE_URL}/plans`,
    { params: fullParams },
  );
  return response.data;
}

export async function fetchAvailablePlanStatuses() {
  const response = await api.get<FetchAvailablePlanStatusesResponse>(
    `${ADS_API_BASE_URL}/fixed-data/available-statuses`,
  );
  return response.data;
}

export async function fetchAvailableReleaseTypes() {
  const response = await api.get<FetchAvailableReleaseTypeResponse>(
    `${ADS_API_BASE_URL}/fixed-data/available-release-types`,
  );
  return response.data;
}

export async function fetchAvailableSanitycheckStatuses() {
  const response = await api.get<FetchAvailableSanitycheckStatusResponse>(
    `${ADS_API_BASE_URL}/fixed-data/available-sanity-statuses`,
  );
  return response.data;
}

export async function fetchAdsUsers(
  profileTypes: AvailableAdsUserProfileTypes[],
) {
  const results = await api.get<FetchAdsUsersResponse<AdsUserEmployee>>(
    `${ADS_API_BASE_URL}/users?limit=1000`,
    {
      params: { profileTypes: profileTypes },
    },
  );
  return results.data;
}

export async function fetchRechargeIds() {
  const response = await api.get<RechargeIdResponse>(
    `${ADS_API_BASE_URL}/fixed-data/available-recharge-ids`,
  );
  return response.data;
}

export async function fetchServiceCodes() {
  const response = await api.get<ServiceCodesResponse>(
    `${ADS_API_BASE_URL}/fixed-data/available-service-codes`,
  );
  return response.data;
}

export async function fetchUserBusinessUnits() {
  const response = await api.get<BusinessUnitsResponse>(
    `${ADS_API_BASE_URL}/business-units`,
  );
  return response.data;
}

export async function createAdsPlans(
  internalProductId,
  projectId,
  rechargeId,
  serviceCode,
  businessUnitId,
  mainArtistId,
  mainArtistName,
): Promise<AdsPlanApiResponse> {
  const data = {
    releaseId: internalProductId,
    projectId,
    rechargeId,
    serviceCode,
    businessUnitId,
    mainArtistId,
    mainArtistName,
  };
  const response = await api.post<AdsPlanApiResponse>(
    `${ADS_API_BASE_URL}/plans`,
    data,
  );
  return response.data;
}

export async function updateAdsPlans(
  updatePlanParams: UpdatePlanPayload,
): Promise<AdsPlanApiResponse> {
  const { planId, ...data } = updatePlanParams;
  const response = await api.put<AdsPlanApiResponse>(
    `${ADS_API_BASE_URL}/plans/${planId}`,
    data,
  );
  return response.data;
}

export async function getAdsPlan(planId): Promise<AdsPlanApiResponse> {
  const response = await api.get<AdsPlanApiResponse>(
    `${ADS_API_BASE_URL}/plans/${planId}`,
  );
  return response.data;
}

export async function fetchAllAdsObjectives(
  isAccessPricingPlanEnabled: boolean,
) {
  const response = await api.get<FetchAdsObjectiveResponse>(
    `${ADS_API_BASE_URL}/fixed-data/objectives`,
  );
  if (!isAccessPricingPlanEnabled) {
    response.data.data = response.data.data.filter(
      (adObjective) => adObjective.featureFlag === undefined,
    );
  }
  return response.data;
}

export async function fetchGroupedAdsObjectives(
  isAccessPricingPlanEnabled: boolean,
) {
  const response = await api.get<FetchAdsObjectiveResponse>(
    `${ADS_API_BASE_URL}/fixed-data/objectives`,
  );

  let allObjectives =
    response?.data?.data?.filter(
      (obj) => isAccessPricingPlanEnabled || obj.featureFlag === undefined,
    ) ?? [];
  const objectiveList: AdsObjective[] = [];

  allObjectives.forEach((objective: AdsObjective) => {
    const similarObjective = objectiveList.filter(
      (value) =>
        value['platform'] === objective['platform'] &&
        value['name'] === objective['name'] &&
        value['category'] === objective['category'] &&
        value['business_unit_type'] === objective['business_unit_type'],
    );

    if (similarObjective.length > 0) {
      // find lowest budget
      let minimumBudget =
        objective['minimumBudget'] != null &&
          similarObjective[0].minimumBudget != null &&
          objective['minimumBudget'] < similarObjective[0].minimumBudget
          ? objective['minimumBudget']
          : similarObjective[0].minimumBudget;

      // find max audience
      let maxAudiences =
        objective['maxAudiences'] != null &&
          similarObjective[0].maxAudiences != null &&
          objective['maxAudiences'] > similarObjective[0].maxAudiences
          ? objective['maxAudiences']
          : similarObjective[0].maxAudiences;

      // update minimum budget & maximum audiences of existing objective
      const index = objectiveList.findIndex((obj) => {
        return obj.id === similarObjective[0].id;
      });

      objectiveList[index].minimumBudget = minimumBudget;
      objectiveList[index].maxAudiences = maxAudiences;
    }

    if (similarObjective.length === 0) {
      objectiveList.push(objective);
    }
  });

  const objectiveListResponse = {
    data: objectiveList,
    status: response.data.status && objectiveList.length > 0,
  };
  return objectiveListResponse;
}

export async function createAdsCampaign(
  selectedObjectives,
  planId,
): Promise<AdsPlanCampaign[]> {
  const campaigns = await Promise.all(
    selectedObjectives.map((objective) =>
      campaignCreation(objective, Number(planId)),
    ),
  );

  let arr: AdsPlanCampaign[] = [];
  campaigns.forEach((campaignArr) => (arr = arr.concat(campaignArr)));

  const uniqueCampaigns = [
    ...new Map(arr.map((item) => [item.id, item])).values(),
  ];

  return uniqueCampaigns;
}

async function campaignCreation(objective, planId) {
  const data = {
    planId: planId,
    platform: objective.platform,
    objectiveName: objective.name,
  };

  const response = await api.post<CreateCampaignApiResponse>(
    `${ADS_API_BASE_URL}/campaigns`,
    data,
  );

  return response?.data?.data?.backstageAdsCampaigns;
}

export async function updateCampaign(
  campaignId: number,
  updatedCampaign: Partial<CampaignUpdateRequest>,
): Promise<UpdateCampaignApiResponse> {
  const response = await api.put<UpdateCampaignApiResponse>(
    `${ADS_API_BASE_URL}/campaigns/${campaignId}`,
    updatedCampaign,
  );
  return response.data;
}

export async function duplicatePlan(
  planId: number,
): Promise<AdsPlanApiResponse> {
  const response = await api.post<AdsPlanApiResponse>(
    `${ADS_API_BASE_URL}/plans/${planId}/duplicate`,
  );
  return response.data;
}

export async function deletePlan(planId: number): Promise<AdsApiResponse> {
  const response = await api.delete<AdsApiResponse>(
    `${ADS_API_BASE_URL}/plans/${planId}`,
  );
  return response.data;
}

export async function duplicateCampaign(
  campaignId: number,
): Promise<AdsPlanApiResponse> {
  const response = await api.post<AdsPlanApiResponse>(
    `${ADS_API_BASE_URL}/campaigns/${campaignId}/duplicate`,
  );
  return response.data;
}

export async function deleteAdsCampaign(
  campaignIdnId: number,
): Promise<AdsApiResponse> {
  const response = await api.delete<AdsApiResponse>(
    `${ADS_API_BASE_URL}/campaigns/${campaignIdnId}`,
  );
  return response.data;
}
export async function fetchBusinessUnitById(businessUnitId: number) {
  const results = await api.get(`ads/business-units/${businessUnitId}`);
  return results.data;
}

export async function getProductFullData(releaseId: string) {
  const results = await api.get(`ads/getProductFullData/${releaseId}`);
  return results.data;
}

export async function addCampaignAudience(
  campaign: AdsPlanCampaign | undefined,
  targetingType: YoutubeTargetingType,
): Promise<UpdateCampaignApiResponse> {
  const data = {
    campaignId: campaign?.id,
    targetingType,
  };

  const response = await api.post<UpdateCampaignApiResponse>(
    `${ADS_API_BASE_URL}/audiences`,
    data,
  );

  return response.data;
}

export async function removeCampaignAudience(
  campaign: AdsPlanCampaign | undefined,
  audienceId: number,
): Promise<AdsApiResponse> {
  const planId = campaign?.planId;
  const campaignId = campaign?.id;

  const response = await api.delete<AdsApiResponse>(
    `${ADS_API_BASE_URL}/plans/${planId}/campaigns/${campaignId}/audiences/${audienceId}`,
  );

  return response.data;
}

export async function updateCampaignAudience(
  updatePayload: UpdateAudiencePayload,
): Promise<UpdateCampaignApiResponse> {
  const { planId, campaignId, audienceId } = updatePayload;

  const response = await api.put<UpdateCampaignApiResponse>(
    `${ADS_API_BASE_URL}/plans/${planId}/campaigns/${campaignId}/audiences/${audienceId}`,
    updatePayload,
  );

  return response.data;
}

export async function searchYoutubeLocations(
  { query, searchKey }: YoutubeFixedDataPayload,
  signal?: AbortSignal
) {
  const response = await api.get<YoutubeLocationResponse>(
    `${ADS_API_BASE_URL}/fixed-data/youtube/locations`,
    {
      params: {
        [searchKey]: query,
        limit: 1000,
      },
      signal: signal as AbortSignal,
    } as AxiosRequestConfig & { signal?: AbortSignal },
  );
  return response.data?.data?.locations ?? [];
}

export async function removeCampaignPlacements(
  campaign: AdsPlanCampaign | undefined,
  placementId: number | undefined,
): Promise<AdsApiResponse> {
  const campaignId = campaign?.id;
  const response = await api.delete<never, AdsApiResponse>(
    `${ADS_API_BASE_URL}/placement/${placementId}`,
    {
      data: {
        campaignId,
      },
    },
  );

  return response;
}

export async function searchYoutubeInterests({
  query,
  searchKey,
}: YoutubeFixedDataPayload,
  signal?: AbortSignal) {
  const response = await api.get<YoutubeInterestResponse>(
    `${ADS_API_BASE_URL}/fixed-data/youtube/interests`,
    {
      params: {
        [searchKey]: query,
        limit: 1000,
      },
      signal: signal as AbortSignal,
    } as AxiosRequestConfig & { signal?: AbortSignal },
  );
  return response.data?.data?.interests ?? [];
}

export async function addCampaignPlacements(
  campaign: AdsPlanCampaign | undefined,
  placement: Partial<YoutubePlacement>,
): Promise<UpdateCampaignApiResponse> {
  const data = {
    campaignId: campaign?.id,
    ...placement,
  };

  const response = await api.post<UpdateCampaignApiResponse>(
    `${ADS_API_BASE_URL}/placements`,
    data,
  );

  return response.data;
}

export async function updateCampaignPlacement(
  updatePayload: UpdateAdsPlacementPayload,
): Promise<UpdateCampaignApiResponse> {
  const { placementId } = updatePayload;

  const response = await api.put<UpdateCampaignApiResponse>(
    `${ADS_API_BASE_URL}/placements/${placementId}`,
    updatePayload,
  );

  return response.data;
}
