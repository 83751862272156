import { DSPActionScheduleType, DSPActionType } from './dspAction.constants';

export type DSPConfig = {
  targetUrl: string;
  targetId: string;
};

export type DSPList = {
  spotify?: DSPConfig;
  deezer?: DSPConfig;
  appleMusic?: DSPConfig;
};

export type DSPActionConfig = {
  platforms: DSPList;
  selectedTimezone?: string;
  useBacklinkRelease?: boolean;
};

/**
 * a DSPAction representation used to interact with Actions as they are handled by the backend
 */
export type DSPAction = {
  id: string;
  type: DSPActionType;
  scheduleType: DSPActionScheduleType;
  config: DSPActionConfig;
  targetName: string;
  artistId?: string;
  isActive?: boolean;
  createdAt?: string;
  updatedAt?: string;
  schedulableOn?: string | null;
  backlink?: any;
  subsKPI?: any;
};

/**
 * a DSPAction subscription
 * i.e. an actual request from a fan to a DSP
 */
export type DSPActionSub = {
  [key: string]: any; // any other prop
};

export const isDSPActionType = (type: string): type is DSPActionType => {
  return Object.values(DSPActionType).includes(type as DSPActionType);
};
