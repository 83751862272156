import produce from 'immer';

import { useBacklinkSettingsEditorState } from 'shared/components/BacklinkSettingsDataEditor/hooks/useBacklinkSettingsDataEditorState';
import { makeDspActionInfos } from 'shared/entities/backlinkSettings/backlinkSettings.helpers';
import {
  BacklinkSettingsData,
  DSPActionInfos,
} from 'shared/entities/backlinkSettings/backlinkSettings.types';
import { DSPAction } from 'shared/entities/dspAction/dspAction.types';

type editorStateHookReturnTypes = ReturnType<
  typeof useBacklinkSettingsEditorState
>;

type Params = {
  getFieldProps: editorStateHookReturnTypes['getFieldProps'];
};

/**
 * a set of helpers for Preview
 *
 */
export function usePreview({ getFieldProps }: Params) {
  /**
   * DSP ACtions Infos
   *
   * we need to use DSP Actions Infos for Preview (for the CTAs tooltips)
   * and we will store those actions infos objects in a special 'preview' branch of the editor state
   * this is because the actual actions objects are only recorded as an independent 'ids' array in the editor state,
   * note: when dislaying a Public Backlink the DSPActionInfos objects per platform are built on the server and passed to the Backlink
   * we need to do the same here for Preview mode as we want all thse infos to come from the editor and reflect the user changes to the actions
   */

  /** returns a change handler for adding an action infos object for a given platform to the editor state */
  const getDSPActionInfosAddHandler = (platformName: string) => {
    const path = `preview.dspActionsInfosByPlatform.${platformName}`;
    const {
      value: dspActionsInfos,
      changeHandler: dspActionsInfosChangeHandler,
    } = getFieldProps(path, {
      replace: true,
    });
    return (dspActionInfos: DSPActionInfos) => {
      dspActionsInfosChangeHandler(
        produce(dspActionsInfos, (draft) => {
          const actionToBeAddedIndex = draft.findIndex(
            (action) => action.id === dspActionInfos.id,
          );
          if (actionToBeAddedIndex === -1) {
            draft.push(dspActionInfos);
          }
        }),
      );
    };
  };

  /** returns a change handler that will remove an action infos object from all platforms */
  const getDSPActionsInfosRemoveHandler = () => {
    const path = `preview.dspActionsInfosByPlatform`;
    const { value: dspActionsInfosByPlatform } = getFieldProps(path);
    return (actionId: string) => {
      Object.keys(dspActionsInfosByPlatform).forEach((platformName) => {
        const path = `preview.dspActionsInfosByPlatform.${platformName}`;
        const {
          value: dspActionsInfos,
          changeHandler: dspActionsInfosChangeHandler,
        } = getFieldProps(path, {
          replace: true,
        });
        dspActionsInfosChangeHandler(
          produce(dspActionsInfos, (draft) => {
            const actionToBeRemovedIndex = draft.findIndex(
              (action) => action.id === actionId,
            );
            if (actionToBeRemovedIndex > -1) {
              draft.splice(actionToBeRemovedIndex, 1);
            }
          }),
        );
      });
    };
  };

  // returns a function that will add dsp actions infos to all platforms
  const getDSPActionsInfosAddHandler = (
    action: DSPAction,
    editorState: BacklinkSettingsData,
  ) => {
    return () => {
      const dspActionInfos = makeDspActionInfos(action, editorState);
      Object.keys(action.config.platforms).forEach((platformName) => {
        const dspActionChangeHandler =
          getDSPActionInfosAddHandler(platformName);
        dspActionChangeHandler(dspActionInfos);
      });
    };
  };

  // update dsp actions infos on all platforms
  const getDSPActionsInfosUpdateHandler = (
    action: DSPAction,
    editorState: BacklinkSettingsData,
  ) => {
    getDSPActionsInfosRemoveHandler()(action.id);
    getDSPActionsInfosAddHandler(action, editorState)();
  };

  return {
    getDSPActionsInfosAddHandler,
    getDSPActionsInfosUpdateHandler,
    getDSPActionsInfosRemoveHandler,
  };
}
